import classNames from "classnames";
import * as React from "react";
import { Button, Menu, Wrapper } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";

import EditMcsForm, { FormValues } from "./EditMcsForm";
import style from "./mcs.scss";
import ConfirmationModal from "components/confirmation/ConfirmationModal";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import KebabIcon from "components/icons/KebabIcon";
import { SuccessIcon } from "components/icons/SuccessIcon";
import { ToastInfo } from "components/icons/ToastInfo";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import { AUTH_MC_EDIT, AUTH_MC_UNREGISTER } from "domain/authority";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie, isCurrentUserInternal, isInTenantAccess } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { trimToUndefined } from "utils/commonFunctions";
import { logger } from "utils/logging";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Result {
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const MENU_ACTIONS = {
    EDIT: "edit",
    UNREGISTER: "unregister",
    RESET: "reset",
    DISABLE: "disable",
    ENABLE: "enable",
} as const;

const MC_STATUS = {
    ACTIVE: "ACTIVE",
    DISABLED: "DISABLED",
    HALTED: "HALTED",
} as const;

const McsKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        name: string;
        description: string;
        uuid: string;
        status: string;
        onMcChange: () => void;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [editMcsFormVisible, setEditMcsFormVisible] = React.useState(false);
    const [disableMcsConfirmationModalVisible, setDisableMcsConfirmationModalVisible] = React.useState(false);
    const [unregisterMcsConfirmationModalVisible, setUnregisterMcsConfirmationModalVisible] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [result, setResult] = React.useState<Result>({
        message: "",
        resultVisible: false,
    });
    const hideResultAndRedirectToRoot = () => {
        setResult({ message: result.message, resultVisible: false });
    };
    const isActiveOrHalted = props.status === MC_STATUS.ACTIVE || props.status === MC_STATUS.HALTED;

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const handleResetClick = () => {
        usageStatisticsService.sendEvent({
            category: Category.MCS,
            action: Action.RESET_MC,
        });
        const abortController = new AbortController();
        abortControllers.push(abortController);
        hybridMcService
            .resetMc(props.uuid, abortController)
            .then((response) => {
                return navigator.clipboard.writeText(response).then(() => {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.copyResetCodeSuccess")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                });
            })
            .catch((error) => {
                if (!abortController?.signal.aborted) {
                    logger.error("Couldn't copy to clipboard", error);
                }
            });
    };
    const hideEditMcsForm = () => {
        setEditMcsFormVisible(false);
    };

    const editMc = async ({ uuid, name: newName, description }: FormValues): Promise<void> => {
        setIsLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);

        const effectiveDescription = trimToUndefined(description);
        newName = newName.trim();
        const effectiveNewName = props.name === newName ? undefined : newName;
        hybridMcService
            .editMc(uuid, effectiveNewName, effectiveDescription, abortController)
            .then(() => {
                if (!abortController.signal.aborted) {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.edit.successMessage", { name: newName })}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                    props.onMcChange();
                }
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    toast(
                        <ToastInfo
                            containerClass={layoutStyle.customToastInfoIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.edit.failureMessage", { name: props.name })}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: classNames(
                                layoutStyle.customToastSuccessMessage,
                                layoutStyle.customToastErrorMessage
                            ),
                        }
                    );
                    logger.error("MC edit failed ", error);
                }
            })
            .finally(() => {
                setIsLoading(false);
                setEditMcsFormVisible(false);
            });
    };

    const toggleDisableMcConfirmationModal = () => {
        setDisableMcsConfirmationModalVisible((prev) => !prev);
    };

    const toggleUnregisterMcConfirmationModal = () => {
        setUnregisterMcsConfirmationModalVisible((prev) => !prev);
    };

    const toggleMcActiveStatus = (action: "enable" | "disable") => {
        setIsLoading(true);
        usageStatisticsService.sendEvent({
            category: Category.MCS,
            action: action === "enable" ? Action.ENABLE_MC : Action.DISABLE_MC,
        });

        const abortController = new AbortController();
        abortControllers.push(abortController);

        hybridMcService
            .toggleMcActiveStatus(props.uuid, action, abortController.signal)
            .catch((error) => {
                logger.error(`Error trying to ${action} MC`, error);
                if (!abortController.signal.aborted) {
                    toast(
                        <ToastInfo
                            containerClass={layoutStyle.customToastInfoIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.toggleActiveStatusFailureMessage", { action: action, name: props.name })}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: classNames(
                                layoutStyle.customToastSuccessMessage,
                                layoutStyle.customToastErrorMessage
                            ),
                        }
                    );
                }
            })
            .finally(() => {
                setDisableMcsConfirmationModalVisible(false);
                setIsLoading(false);
                props.onMcChange();
            });
    };

    const unregisterMc = () => {
        setIsLoading(true);

        usageStatisticsService.sendEvent({
            category: Category.MCS,
            action: Action.UNREGISTER_MC,
        });

        const abortController = new AbortController();
        abortControllers.push(abortController);

        hybridMcService
            .unregisterMc(props.uuid, abortController.signal)
            .then(() => {
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("Mcs.unregisterMcSuccess", { name: props.name })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
            })
            .catch((error) => {
                logger.error(`Error trying to unregister MC`, error);
                if (!abortController.signal.aborted) {
                    toast(
                        <ToastInfo
                            containerClass={layoutStyle.customToastInfoIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.unregisterFailureMessage", { name: props.name })}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: classNames(
                                layoutStyle.customToastSuccessMessage,
                                layoutStyle.customToastErrorMessage
                            ),
                        }
                    );
                }
            })
            .finally(() => {
                setUnregisterMcsConfirmationModalVisible(false);
                setIsLoading(false);
                props.onMcChange();
            });
    };

    const handleSelection = (value: string) => {
        switch (value) {
            case MENU_ACTIONS.EDIT:
                setEditMcsFormVisible(true);
                usageStatisticsService.sendEvent({
                    category: Category.MCS,
                    action: Action.EDIT_MC,
                });
                break;
            case MENU_ACTIONS.UNREGISTER:
                toggleUnregisterMcConfirmationModal();
                break;
            case MENU_ACTIONS.RESET:
                handleResetClick();
                break;
            case MENU_ACTIONS.DISABLE:
                toggleDisableMcConfirmationModal();
                break;
            case MENU_ACTIONS.ENABLE:
                toggleMcActiveStatus(value);
                break;
            default:
                logger.error("Unknown menu action", value);
                break;
        }
    };

    const editVisible = userSessionService.userHasAllAuthorities([AUTH_MC_EDIT]);
    const unregisterVisible = userSessionService.userHasAllAuthorities([AUTH_MC_UNREGISTER]);
    return (
        <div className={classNames(style.tenantIcon)}>
            <Wrapper
                className={classNames(style.kebabIconContainer)}
                onSelection={(value) => {
                    handleSelection(value);
                }}
            >
                <Button
                    className={style.kebabMenuButton}
                    data-testid={testIds.common.primaryView.table.kebabMenu.button}
                >
                    <KebabIcon color={props.theme.iconFillColor} />
                </Button>
                <Menu className={style.kebabMenu}>
                    <ul>
                        {!hasTenantCookie() && editVisible && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    value={MENU_ACTIONS.EDIT}
                                    className={style.menuItem}
                                    data-testid={testIds.workArea.mcs.table.kebabMenu.editButton}
                                >
                                    {t("Mcs.edit.title")}
                                </MenuItemButton>
                            </li>
                        )}
                        {isActiveOrHalted && !hasTenantCookie() && unregisterVisible && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    value={MENU_ACTIONS.UNREGISTER}
                                    className={style.menuItem}
                                    data-testid={testIds.workArea.mcs.table.kebabMenu.unregisterButton}
                                >
                                    {t("Mcs.unregister")}
                                </MenuItemButton>
                            </li>
                        )}
                        {!hasTenantCookie() && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    value={MENU_ACTIONS.RESET}
                                    className={style.menuItem}
                                    data-testid={testIds.workArea.mcs.table.kebabMenu.resetButton}
                                >
                                    {t("Mcs.reset")}
                                </MenuItemButton>
                            </li>
                        )}
                        {props.status === MC_STATUS.ACTIVE && isCurrentUserInternal() && isInTenantAccess() && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    value={MENU_ACTIONS.DISABLE}
                                    className={style.menuItem}
                                    data-testid={testIds.workArea.mcs.table.kebabMenu.disableButton}
                                >
                                    {t("Mcs.disable")}
                                </MenuItemButton>
                            </li>
                        )}
                        {props.status === MC_STATUS.DISABLED && isCurrentUserInternal() && isInTenantAccess() && (
                            <li className={style.listItem}>
                                <MenuItemButton
                                    value={MENU_ACTIONS.ENABLE}
                                    className={style.menuItem}
                                    data-testid={testIds.workArea.mcs.table.kebabMenu.enableButton}
                                >
                                    {t("Mcs.enable")}
                                </MenuItemButton>
                            </li>
                        )}
                    </ul>
                </Menu>
            </Wrapper>
            <Modal isOpen={editMcsFormVisible} hideModal={hideEditMcsForm} modalTitle={t("Mcs.edit.editTitle")}>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <EditMcsForm
                        name={props.name}
                        uuid={props.uuid}
                        description={props.description}
                        handleCancel={() => setEditMcsFormVisible(false)}
                        handleSubmit={editMc}
                    />
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot}>
                <div className={style.resultContainer}>{result.message}</div>
            </Modal>

            <ConfirmationModal
                isOpen={disableMcsConfirmationModalVisible}
                hideModal={toggleDisableMcConfirmationModal}
                isLoading={isLoading}
                onConfirm={() => toggleMcActiveStatus("disable")}
                title={t("Mcs.disableMcConfirmationTitle")}
                subtitle={"'" + props.name + "'"}
                actionButtonLabel={t("Mcs.disable")}
            />

            <ConfirmationModal
                isOpen={unregisterMcsConfirmationModalVisible}
                hideModal={toggleUnregisterMcConfirmationModal}
                isLoading={isLoading}
                onConfirm={unregisterMc}
                title={t("Mcs.unregisterMcConfirmationTitle")}
                subtitle={t("Mcs.unregisterMcConfirmationSubtitle")}
                actionButtonLabel={t("Mcs.unregister")}
            />
        </div>
    );
};

export default connector(McsKebabMenu);
