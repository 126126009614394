import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { DateRangePicker } from "rsuite";
import { DateRange, RangeType } from "rsuite/esm/DateRangePicker";
import {
    addDays,
    addMonths,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    subDays,
} from "rsuite/esm/utils/dateUtils";
import { PickerHandle } from "rsuite/Picker";

import style from "./custom-date-picker.scss";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import { updateReportViewSettings } from "store/reportViewSettings";
import formStyle from "styles/form.scss";
import { formatDateWithoutTime, toConflictAdjustedDate, toUtcDateString } from "utils/format";
import { logger } from "utils/logging";

import testIds from "testIds.json";

export enum DateRangeSelection {
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    THIS_WEEK = "THIS_WEEK",
    LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
    THIS_MONTH = "THIS_MONTH",
    LAST_MONTH = "LAST_MONTH",
    LAST_THIRTY_DAYS = "LAST_THIRTY_DAYS",
    THIS_YEAR = "THIS_YEAR",
    LAST_YEAR = "LAST_YEAR",
    CUSTOM = "CUSTOM",
    ALL = "ALL",
}

export const ALL_REPORTS_VIEW_IDENTIFIER = "allReports";
const OVERLAY_CLOSE = false;
const OVERLAY_PLACEMENT = "left";
const TEST_ID_SET_FAILURE_MESSAGE =
    "Failed to find native element for date range picker overlay when trying to set test IDs.";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    settings: state.reportViewSettings,
});

// TODO BCC-3953 Update this
const connector = connect(mapState, { updateReportViewSettings });

enum LocalizationKey {
    TODAY = "Common.dateRanges.today",
    YESTERDAY = "Common.dateRanges.yesterday",
    THIS_WEEK = "Common.dateRanges.thisWeek",
    LAST_SEVEN_DAYS = "Common.dateRanges.lastSevenDays",
    THIS_MONTH = "Common.dateRanges.thisMonth",
    LAST_MONTH = "Common.dateRanges.lastMonth",
    LAST_THIRTY_DAYS = "Common.dateRanges.lastThirtyDays",
    THIS_YEAR = "Common.dateRanges.thisYear",
    LAST_YEAR = "Common.dateRanges.lastYear",
    CUSTOM = "Common.dateRanges.custom",
    ALL = "Common.dateRanges.all",
}

const RANGE_TO_LOCALIZATION_KEY_MAP = new Map<DateRangeSelection, string>([
    [DateRangeSelection.TODAY, LocalizationKey.TODAY],
    [DateRangeSelection.YESTERDAY, LocalizationKey.YESTERDAY],
    [DateRangeSelection.THIS_WEEK, LocalizationKey.THIS_WEEK],
    [DateRangeSelection.LAST_SEVEN_DAYS, LocalizationKey.LAST_SEVEN_DAYS],
    [DateRangeSelection.THIS_MONTH, LocalizationKey.THIS_MONTH],
    [DateRangeSelection.LAST_MONTH, LocalizationKey.LAST_MONTH],
    [DateRangeSelection.LAST_THIRTY_DAYS, LocalizationKey.LAST_THIRTY_DAYS],
    [DateRangeSelection.THIS_YEAR, LocalizationKey.THIS_YEAR],
    [DateRangeSelection.LAST_YEAR, LocalizationKey.LAST_YEAR],
    [DateRangeSelection.ALL, LocalizationKey.ALL],
    [DateRangeSelection.CUSTOM, LocalizationKey.CUSTOM],
]);

interface Props {
    defaultDateRange: DateRange;
    onUpdate: (startDate?: string, endDate?: string) => void;
    uuid?: string;
    defaultPlaceholder: boolean;
    category: Category;
    editable: boolean;
}

function fromConflictAdjustedDate(date: Date): Date {
    const result = new Date(date);
    result.setSeconds(date.getSeconds() + 1);
    return result;
}

const CustomDatePickerView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const today: DateRange = [startOfDay(new Date()), endOfDay(new Date())];
    const yesterday: DateRange = [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))];
    const thisWeek: DateRange = [startOfISOWeek(new Date()), endOfISOWeek(new Date())];
    const lastSevenDays: DateRange = [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())];
    const thisMonth: DateRange = [startOfMonth(new Date()), endOfMonth(new Date())];
    const lastMonth: DateRange = [
        startOfMonth(addMonths(new Date(), -1)),
        toConflictAdjustedDate(endOfMonth(addMonths(new Date(), -1))),
    ];
    const lastThirtyDays: DateRange = [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())];
    const thisYear: DateRange = [
        startOfMonth(new Date(new Date().getFullYear(), 0)),
        endOfMonth(new Date(new Date().getFullYear(), 11)),
    ];
    const lastYear: DateRange = [
        startOfMonth(new Date(new Date().getFullYear() - 1, 0)),
        endOfMonth(new Date(new Date().getFullYear() - 1, 11)),
    ];
    const selectableRanges: RangeType[] = [];
    const localizationToStatisticsLabelMap = new Map<string, Label>();
    const dateRangeSelectionToDateRangeMap = new Map<DateRangeSelection, DateRange>();
    [
        [t(LocalizationKey.TODAY), Label.TODAY, DateRangeSelection.TODAY, today],
        [t(LocalizationKey.YESTERDAY), Label.YESTERDAY, DateRangeSelection.YESTERDAY, yesterday],
        [t(LocalizationKey.THIS_WEEK), Label.THIS_WEEK, DateRangeSelection.THIS_WEEK, thisWeek],
        [t(LocalizationKey.LAST_SEVEN_DAYS), Label.LAST_SEVEN_DAYS, DateRangeSelection.LAST_SEVEN_DAYS, lastSevenDays],
        [t(LocalizationKey.THIS_MONTH), Label.THIS_MONTH, DateRangeSelection.THIS_MONTH, thisMonth],
        [t(LocalizationKey.LAST_MONTH), Label.LAST_MONTH, DateRangeSelection.LAST_MONTH, lastMonth],
        [
            t(LocalizationKey.LAST_THIRTY_DAYS),
            Label.LAST_THIRTY_DAYS,
            DateRangeSelection.LAST_THIRTY_DAYS,
            lastThirtyDays,
        ],
        [t(LocalizationKey.THIS_YEAR), Label.THIS_YEAR, DateRangeSelection.THIS_YEAR, thisYear],
        [t(LocalizationKey.LAST_YEAR), Label.LAST_YEAR, DateRangeSelection.LAST_YEAR, lastYear],
    ].map((rangeSpecification: [string, Label, DateRangeSelection, DateRange]) => {
        selectableRanges.push({
            label: rangeSpecification[0],
            value: rangeSpecification[3],
            placement: OVERLAY_PLACEMENT,
            closeOverlay: OVERLAY_CLOSE,
        });
        localizationToStatisticsLabelMap.set(rangeSpecification[0], rangeSpecification[1]);
        dateRangeSelectionToDateRangeMap.set(rangeSpecification[2], rangeSpecification[3]);
    });

    let initialValue: DateRange | undefined = undefined;
    let viewIdentifier = ALL_REPORTS_VIEW_IDENTIFIER;
    if (props.category === Category.REPORTS) {
        viewIdentifier = props.uuid ?? ALL_REPORTS_VIEW_IDENTIFIER;
        const viewSettings = props.settings[viewIdentifier];
        if (viewSettings) {
            const range = viewSettings.selectedRange;
            switch (range) {
                case DateRangeSelection.TODAY:
                case DateRangeSelection.YESTERDAY:
                case DateRangeSelection.THIS_WEEK:
                case DateRangeSelection.LAST_SEVEN_DAYS:
                case DateRangeSelection.THIS_MONTH:
                case DateRangeSelection.LAST_MONTH:
                case DateRangeSelection.LAST_THIRTY_DAYS:
                case DateRangeSelection.THIS_YEAR:
                case DateRangeSelection.LAST_YEAR:
                    initialValue = dateRangeSelectionToDateRangeMap.get(range);
                    break;
                case DateRangeSelection.CUSTOM:
                    if (viewSettings.startDate && viewSettings.endDate) {
                        initialValue = [new Date(viewSettings.startDate), new Date(viewSettings.endDate)];
                    }
                    break;
                case DateRangeSelection.ALL:
                    break;
            }
        }
    }

    const dateRangePicker = React.useRef<PickerHandle>(null);
    const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange | null>(initialValue ?? null);

    const renderValue = (value: DateRange) => {
        const startDate = formatDateWithoutTime(value[0].toString());
        const endDate = formatDateWithoutTime(value[1].toString());
        if (startDate === endDate) {
            return `${startDate}`;
        }
        return `${startDate} - ${endDate}`;
    };

    function getLocalizationKey(selection: DateRangeSelection): string {
        const result = RANGE_TO_LOCALIZATION_KEY_MAP.get(selection);
        if (!result) {
            throw new Error("Unknown date range selection: " + selection);
        }
        return result;
    }

    const onSelect = () => {
        sendStatisticsEvent(Action.SELECT_DATE_RANGE, Label.CUSTOM);
    };

    const closeButton = document.getElementsByClassName("rs-picker-toggle-clean rs-btn-close")[0] as HTMLElement;
    if (closeButton) {
        closeButton.setAttribute("class", style.filterCross);
        closeButton.setAttribute("alt", t("AltText.clearIcon"));
    }
    const handleOkClick = (dateRange: DateRange) => {
        const startDate = startOfDay(dateRange[0]);
        let endDate = endOfDay(dateRange[1]);
        const range = toSelectedDateRange(dateRange);
        if (range === DateRangeSelection.LAST_MONTH) {
            endDate = fromConflictAdjustedDate(endDate);
        }
        const startDateUtcIsoString = toUtcDateString(startDate);
        const endDateUtcIsoString = toUtcDateString(endDate);
        if (props.category === Category.REPORTS) {
            props.updateReportViewSettings({
                identifier: viewIdentifier,
                selectedRange: range,
                startDate: startDateUtcIsoString,
                endDate: endDateUtcIsoString,
            });
        }
        props.onUpdate(startDateUtcIsoString, endDateUtcIsoString);
        setSelectedDateRange(dateRange);
    };

    const handleClean = () => {
        initialValue = undefined;
        sendStatisticsEvent(Action.SELECT_DATE_RANGE, Label.ALL);
        if (props.category === Category.REPORTS) {
            props.updateReportViewSettings({ identifier: viewIdentifier, selectedRange: DateRangeSelection.ALL });
        }
        props.onUpdate();
    };

    const onEntered = () => {
        sendStatisticsEvent(Action.VIEW_DATE_RANGE_FILTER);

        const overlay = dateRangePicker.current?.overlay as HTMLElement;
        if (!overlay) {
            logger.error(TEST_ID_SET_FAILURE_MESSAGE);
            return;
        }
        const buttonContainer = overlay.getElementsByClassName("rs-picker-daterange-predefined")[0] as HTMLElement;
        if (!buttonContainer) {
            logger.error(TEST_ID_SET_FAILURE_MESSAGE);
            return;
        }

        buttonContainer.dataset.testid = testIds.common.dateRangePicker.predefinedRangeButtonContainer.itself;
        buttonContainer.addEventListener("click", (event) => {
            const target = event.target as HTMLElement;
            if (target && target.tagName === "BUTTON") {
                const label = localizationToStatisticsLabelMap.get(target.innerText);
                if (!label) {
                    return;
                }
                sendStatisticsEvent(Action.SELECT_DATE_RANGE, label);
            }
        });

        const toolBarContainer = overlay.getElementsByClassName("rs-picker-toolbar-right")[0] as HTMLElement;
        if (toolBarContainer) {
            const okButton = toolBarContainer.getElementsByClassName(
                "rs-btn rs-btn-primary rs-btn-sm"
            )[0] as HTMLElement;
            okButton.dataset.testid = testIds.common.dateRangePicker.okButton;
        }
    };

    const compareDateRanges = (first: DateRange, second: DateRange) => {
        return first[0].getTime() === second[0].getTime() && first[1].getTime() === second[1].getTime();
    };

    const toSelectedDateRange = (dateRange: DateRange): DateRangeSelection => {
        for (const [key, value] of dateRangeSelectionToDateRangeMap) {
            if (compareDateRanges(dateRange, value)) {
                return key;
            }
        }
        return DateRangeSelection.CUSTOM;
    };

    const [enterDate, setEnterDate] = React.useState<DateRange>();

    function sendStatisticsEvent(action: Action, label?: Label) {
        usageStatisticsService.sendEvent({
            category: props.category,
            action: action,
            label: label,
        });
    }

    return (
        <DateRangePicker
            ref={dateRangePicker}
            // To force component to update properly during the clean action (clicking the cross icon), we pass a null
            // instead of undefined, which would otherwise be passed in such a scenario.
            value={selectedDateRange}
            editable={props.editable}
            placement={"autoVerticalEnd"}
            ranges={selectableRanges}
            onOk={handleOkClick}
            onSelect={onSelect}
            onClean={handleClean}
            defaultValue={props.defaultDateRange}
            renderValue={renderValue}
            onEntered={onEntered}
            className={formStyle.dateRange}
            placeholder={
                props.defaultPlaceholder
                    ? t(getLocalizationKey(DateRangeSelection.LAST_THIRTY_DAYS))
                    : t(getLocalizationKey(DateRangeSelection.ALL))
            }
            isoWeek={true}
            limitEndYear={100}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter" && enterDate != undefined) {
                    handleOkClick(enterDate);
                }
            }}
            onChange={(e: DateRange) => {
                setEnterDate(e);
            }}
        />
    );
};

export default connector(CustomDatePickerView);
