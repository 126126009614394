import * as React from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { addDays, startOfDay } from "rsuite/esm/utils/dateUtils";

import style from "./view-tenant-details.scss";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import { createLicenseTypes } from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { WarningBadge } from "components/warning/WarningBadge";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { LicenseData } from "domain/licenses";
import { Tenant } from "domain/tenants";
import { generateTenantTypeToTranslationMap, generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { licenseService } from "services/licenses/LicenseService";
import { isUserParentInternal } from "services/tenants/tenantCookieService";
import tabsStyle from "styles/react-tabs.scss";
import { isStringNotBlank } from "utils/commonFunctions";
import { createDateLabel, formatDateWithoutTime } from "utils/format";

import testIds from "testIds.json";

export const ViewTenantDetails = (props: { activeTenant: Tenant }): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [licenses, setLicenses] = React.useState<LicenseData[]>([]);
    const [failedRequest, setFailedRequest] = React.useState<string>();
    function createInformationSegment(testId: string, label: string, value?: string | number) {
        let deducedValue = value;
        if (typeof value === "undefined") {
            deducedValue = "-";
        } else {
            if (typeof value === "number") {
                deducedValue = value;
            } else {
                deducedValue = isStringNotBlank(value) ? value : "-";
            }
        }
        return (
            <>
                <div className={style.label}>{label}</div>
                <div data-testid={testId}>{deducedValue}</div>
            </>
        );
    }
    function fetchLicenses() {
        if (licenses.length > 0) {
            return;
        }
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchLicenses(abortController, undefined, false, props.activeTenant.uuid, undefined, true, true)
            .then((data) => {
                setLicenses(data.licenses);
            })
            .catch(() => {
                setFailedRequest(
                    t("CustomerProfile.licenseOverview.failedRequest", {
                        email: SUPPORT_EMAIL,
                    })
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }
    function createTableForAvailableLicenses() {
        const columns = [
            { value: t("CustomerProfile.licenseOverview.product"), className: style.licenseNameColumnWidth },
            { value: t("CustomerProfile.licenseOverview.ownBalance") },
            { value: t("CustomerProfile.licenseOverview.balance") },
        ];
        const cells: (string | JSX.Element | null)[][] | undefined = [];
        const blanccoProducts = createLicenseTypes(true);
        if (licenses.length > 0) {
            const sortedProducts = licenses.map((each) => {
                const foundProduct = blanccoProducts.find((product) => product.productId === each.type);
                return {
                    productName: foundProduct?.productName,
                    ownBalance: each.overallRemaining,
                    recursiveBalance: each.recursiveBalance,
                };
            });
            sortedProducts.forEach((each) => {
                cells.push([
                    <div key={each.productName}>{each.productName}</div>,
                    <div key={each.productName}>{each.ownBalance}</div>,
                    <div key={each.productName} className={style.label}>
                        {each.recursiveBalance ?? " - "}
                    </div>,
                ]);
            });
            return (
                <>
                    <div className={style.standardModalTitle}>{t("CustomerProfile.licenseOverview.introduction")}</div>
                    <div>
                        <StaticTable
                            headers={columns}
                            cells={cells}
                            tableClass={style.licenseTableWidth}
                            testId={testIds.workArea.tenant.viewTenantDialog.licenseOverview.table}
                        ></StaticTable>
                    </div>
                </>
            );
        } else {
            if (failedRequest) {
                return <div className={style.standardModalTitle}>{failedRequest}</div>;
            }
        }
    }
    return (
        <Tabs className={tabsStyle.tabsSegmentVariant}>
            <TabList>
                <Tab data-testid={testIds.workArea.tenant.viewTenantDialog.generic.tab}>
                    {t("CustomerProfile.tab.tenantDetails")}
                </Tab>
                <Tab
                    data-testid={testIds.workArea.tenant.viewTenantDialog.licenseOverview.tab}
                    onClick={() => {
                        fetchLicenses();
                    }}
                >
                    {t("CustomerProfile.tab.licenseOverview")}
                </Tab>
            </TabList>
            <TabPanel>
                <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                    {t("CustomerProfile.genericDetail")}
                </Heading>
                <div className={style.tenantProfile}>
                    <div className={style.label}>{t("CustomerProfile.customerIdentifier")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.uuidLabel}>
                        {props.activeTenant.uuid}
                        <CopyToClipboard value={props.activeTenant.uuid} />
                    </div>

                    {createInformationSegment(
                        testIds.workArea.tenant.viewTenantDialog.generic.regionLabel,
                        t("CustomerProfile.region"),
                        props.activeTenant.region
                    )}
                    {createInformationSegment(
                        testIds.workArea.tenant.viewTenantDialog.generic.countryLabel,
                        t("CustomerProfile.country"),
                        props.activeTenant.countryCode
                    )}
                    {createInformationSegment(
                        testIds.workArea.tenant.viewTenantDialog.generic.createdDateLabel,
                        t("CustomerProfile.createdDate"),
                        createDateLabel(props.activeTenant.createdTimestamp)
                    )}
                    {createInformationSegment(
                        testIds.workArea.tenant.viewTenantDialog.generic.createdByLabel,
                        t("CustomerProfile.createdBy"),
                        props.activeTenant.createdBy
                    )}
                    {createInformationSegment(
                        testIds.workArea.tenant.viewTenantDialog.generic.createdByLabel,
                        t("AddCustomerForm.type"),
                        generateTenantTypeToTranslationMap(t).get(props.activeTenant.type)
                    )}
                    {createInformationSegment(
                        testIds.workArea.tenant.viewTenantDialog.generic.createdByLabel,
                        t("AddCustomerForm.blanccoBundles"),
                        generateTierToTranslationMap(t).get(props.activeTenant.tier)
                    )}
                    <div className={style.label}>{t("CustomerProfile.notes")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.notesLabel}>
                        {props.activeTenant.notes !== "" ? (
                            props.activeTenant.notes
                        ) : (
                            <div className={style.emptyField}>{t("CustomerProfile.noNotesAvailable")}</div>
                        )}
                    </div>
                </div>
                <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                    {t("CustomerProfile.contactDetails")}
                </Heading>
                <div className={style.tenantProfile}>
                    <div className={style.label}>{t("CustomerProfile.mainContactName")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.contact.mainContactNameLabel}>
                        {props.activeTenant.contactName !== "" ? (
                            props.activeTenant.contactName
                        ) : (
                            <div className={style.emptyField}>{t("Common.notProvided")}</div>
                        )}
                    </div>

                    <div className={style.label}>{t("CustomerProfile.email")}</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.contact.emailLabel}>
                        {props.activeTenant.contactEmail !== "" ? (
                            props.activeTenant.contactEmail
                        ) : (
                            <div className={style.emptyField}>{t("Common.notProvided")}</div>
                        )}
                        {props.activeTenant.contactEmail !== "" && (
                            <CopyToClipboard value={props.activeTenant.contactEmail} />
                        )}
                    </div>
                </div>
                <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                    {t("CustomerProfile.statusSettings")}
                </Heading>
                <div className={style.tenantProfile}>
                    <div className={style.label}>{t("CustomerProfile.status")}</div>
                    <StatusBadge
                        testId={testIds.workArea.tenant.viewTenantDialog.statusSettings.statusLabel}
                        values={[
                            {
                                status: props.activeTenant.status ? Status.SUCCESS : Status.ERROR,
                                title: props.activeTenant.status ? t("Common.active") : t("Common.inactive"),
                            },
                        ]}
                        tooltip={true}
                    />
                    <div className={style.label}>{t("Common.accessExpiry")}:</div>
                    <div data-testid={testIds.workArea.tenant.viewTenantDialog.statusSettings.expirationDateLabel}>
                        {props.activeTenant.expirationDate == "" || props.activeTenant.expirationDate == undefined
                            ? t("Common.never")
                            : createDateLabel(props.activeTenant.expirationDate)}
                    </div>
                </div>
                {isUserParentInternal() && (
                    <>
                        <Heading tag="div" variant="SUBTITLE_1" className={style.standardModalTitle}>
                            {t("CustomerProfile.salesForceAccount")}
                        </Heading>
                        <div className={style.tenantProfile}>
                            {createInformationSegment(
                                testIds.workArea.tenant.viewTenantDialog.salesForceAccount.salesForceAccountIdLabel,
                                t("CustomerProfile.salesForceAccountId"),
                                props.activeTenant.salesforceAccountId
                            )}
                            {createInformationSegment(
                                testIds.workArea.tenant.viewTenantDialog.salesForceAccount.contractIdLabel,
                                t("CustomerProfile.contractId"),
                                props.activeTenant.salesforceContractId
                            )}
                            {createInformationSegment(
                                testIds.workArea.tenant.viewTenantDialog.salesForceAccount.contractStateLabel,
                                t("CustomerProfile.contractState"),
                                props.activeTenant.salesforceContractState
                            )}
                            {createInformationSegment(
                                testIds.workArea.tenant.viewTenantDialog.salesForceAccount.contractEmailLabel,
                                t("CustomerProfile.contractEmail"),
                                props.activeTenant.salesforceContactEmail
                            )}
                        </div>
                    </>
                )}
                <>
                    <Heading tag={"div"} variant="SUBTITLE_1" className={style.standardModalTitle}>
                        {t("CustomerProfile.usageStatistics.title")}
                    </Heading>
                    <div>
                        <WarningBadge
                            note={t("CustomerProfile.usageStatistics.note", {
                                date: formatDateWithoutTime(startOfDay(addDays(new Date(), -1)).toString()),
                            })}
                        />
                    </div>
                    <div className={style.tenantProfile}>
                        {createInformationSegment(
                            testIds.workArea.tenant.viewTenantDialog.usageStatistics.usersLabel,
                            t("CustomerProfile.usageStatistics.users"),
                            props.activeTenant.totalUsers
                        )}
                        {createInformationSegment(
                            testIds.workArea.tenant.viewTenantDialog.usageStatistics.workflowsLabel,
                            t("CustomerProfile.usageStatistics.workflows"),
                            props.activeTenant.totalWorkflows
                        )}
                        {createInformationSegment(
                            testIds.workArea.tenant.viewTenantDialog.usageStatistics.reportsLabel,
                            t("CustomerProfile.usageStatistics.reports"),
                            props.activeTenant.totalReportsCount
                        )}
                        {createInformationSegment(
                            testIds.workArea.tenant.viewTenantDialog.usageStatistics.subtenantsLabel,
                            t("CustomerProfile.usageStatistics.subtenants"),
                            props.activeTenant.subTenantsCount
                        )}
                    </div>
                </>
            </TabPanel>
            <TabPanel>{loading ? <LoadingIndicator /> : failedRequest || createTableForAvailableLicenses()}</TabPanel>
        </Tabs>
    );
};
